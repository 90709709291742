<script setup>
import { ref, watchEffect } from 'vue';
import { ArrowLeftIcon } from '@heroicons/vue/24/outline';
import AppIcon from '@/customer/Components/AppIcon.vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import CartItem from '@/customer/Components/Cart/CartItem.vue';
import AddOnItems from '@/customer/Components/Cart/AddOnItems.vue';
import { useCartStore, useOrderStore } from '@/customer/Composables';
import { MenuType } from '@/common/Utils/enums.js';
import InputText from '@/customer/Components/InputText.vue';
import BaseButton from '@/customer/Components/BaseButton.vue';
import { ErrorBoundary } from '@/common/Components';

const cart = useCartStore();

const order = useOrderStore();

const quantity = ref(null);

watchEffect(() => {
    quantity.value = cart.count;
});
</script>

<template>
    <div class="flex items-start justify-between border-b-2 !px-4 sm:!px-8 py-8">
        <DialogTitle class="flex flex-col">
            <span class="text-2xl font-semibold text-palmers-charcoal-grey"
                >Your {{ order.isCatering ? 'Catering' : 'Order' }}</span
            >
            <span class="text-lg uppercase opacity-80">Items: {{ quantity }}</span>
            <button
                type="button"
                class="flex flex-col text-gray-400 underline supports-hover:hover:text-gray-500"
                @click="cart.reset"
            >
                <span>Clear All</span>
            </button>
        </DialogTitle>
        <div>
            <button
                type="button"
                class="flex items-center gap-x-2 p-2 text-gray-400 supports-hover:hover:text-gray-500 supports-hover:hover:underline"
                @click="cart.close"
            >
                <ArrowLeftIcon class="size-4 stroke-[3]" aria-hidden="true" />
                <p class="max-w-40 text-right">Back To Menu</p>
            </button>
        </div>
    </div>
</template>
