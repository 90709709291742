<script setup>
import { CheckboxGroup } from '@/company/Components';
import { isEqual, get } from 'lodash';
import { GridItem } from '.';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
    by: {
        type: Function,
        default: isEqual,
    },
    modelValue: {
        type: [Object, Array],
        default: () => [],
    },
    defaultValue: {
        type: [Object, Array],
        default: () => [],
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    options: {
        type: Array,
        default: () => [],
    },
    name: { type: String, default: 'checkboxes' },
    labelKey: { type: String, default: 'label' },
    valueKey: { type: String, default: 'value' },
});

const optValue = (option) => {
    return get(option, props.valueKey);
};

const optLabel = (option) => {
    return get(option, props.labelKey);
};

const handleUpdate = ($event) => {
    console.log('handle updatee');
    emit('update:modelValue', $event);
};
</script>

<template>
    <CheckboxGroup
        class="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3"
        :model-value="modelValue"
        @update:model-value="emit('update:modelValue', $event)"
    >
        <template v-for="option in options" :key="optValue(option)">
            <GridItem v-bind="{ choice: option }" :value="optValue(option)" />
        </template>
    </CheckboxGroup>
</template>
