import { defineStore } from 'pinia';
import mitt from 'mitt';
import asyncDebounce, { mapAndKey, str_camel, str_studly } from '@/common/Utils';
import { debounce, get, isEmpty, keyBy } from 'lodash';
import { usePage } from '@inertiajs/vue3';
import { toRef, toValue, unref } from 'vue';
import { formatCurrency } from '../Utils';

export default defineStore('cartStore', {
    state: () => ({
        isOpen: false,
        contents: {},
        processing: false,
        bus: mitt(),
        debounce: {},
        errors: {},
        discountCode: null,
    }),
    getters: {
        isEmpty() {
            return isEmpty(get(this.contents, 'items'));
        },
        count() {
            return get(this.contents, 'summary.quantity', 0);
        },
        items() {
            return keyBy(Object.values(get(this.contents, 'items', [])), 'uuid');
        },
        menus() {
            return Object.values(get(this.contents, 'menus', []));
        },
        tree() {
            return Object.values(get(this.contents, 'tree', []));
        },
        summary() {
            return get(this.contents, 'summary', {});
        },
        indvGrossQuantity: (state) => (uuid) => {
            return state.items[uuid].quantity;
        },
    },
    actions: {
        on(eventName, eventHandler) {
            this.bus.on(eventName, eventHandler);
        },
        open() {
            this.isOpen = true;
            // this.bus.emit('open');
        },
        scrollToBottom() {
            this.bus.emit('scrollToBottom');
        },
        close() {
            this.isOpen = false;
            this.bus.emit('close');
        },
        toggle() {
            this.isOpen ? this.close() : this.open();
        },
        beginProcessing(eventName, deps = []) {
            this.processing = true;
            this.bus.emit('beginProcessing', eventName, deps);
        },
        endProcessing(eventName, deps = []) {
            this.processing = true;
            this.bus.emit('endProcessing', eventName, deps);
        },
        async hook(eventName, handler, deps = []) {
            this.errors = {};
            this.bus.emit(`before${str_studly(eventName)}`, ...deps);
            await new Promise((resolve, reject) => {
                let resolvedData = null;
                let error = false;
                handler
                    .then((...args) => {
                        resolvedData = args;
                        this.bus.emit(`${str_camel(eventName)}`, ...resolvedData);
                    })
                    .catch((...args) => {
                        error = true;
                        resolvedData = args;
                        this.errors = resolvedData?.[0]?.response?.data?.errors ?? {};
                        Object.keys(this.errors).forEach((key) => {
                            this.errors[key] = this.errors[key]?.[0] ?? this.errors[key];
                        });
                        this.bus.emit(`${str_camel(eventName)}Error`, ...resolvedData);
                    })
                    .finally((...args) => {
                        this.bus.emit(`${str_camel(eventName)}Finally`);
                        if (error) {
                            reject(resolvedData);
                        } else {
                            resolve(resolvedData);
                        }
                    });
            });
        },
        async debounce(id, debounce, args) {
            this.debounce[id] ??= asyncDebounce(this[id], debounce);
            return await this.debounce[id](...args);
        },
        async process(eventName, handler, deps = []) {
            this.beginProcessing(eventName, deps);
            await this.hook(eventName, handler, deps);
            this.endProcessing(eventName, deps);
        },
        async initialize() {
            document.addEventListener('visibilitychange', () => {
                if (!document.hidden) {
                    this.fetchCart();
                }
            });
            await this.process(
                'initialize',
                new Promise((resolve, reject) => {
                    const cart = Object.assign({}, usePage().props?.cart ?? {});

                    if (!Object.keys(cart).length) {
                        return this.fetchCart();
                    }
                    this.setContents(cart);
                    resolve(cart);
                }),
            );
        },
        async fetchCart() {
            await this.process(
                'fetchCart',
                axios.get(route('cart.index')).then((response) => {
                    this.setContents(response.data);
                    return response.data;
                }),
            );
        },
        async addItem(item) {
            await this.process(
                'addItem',
                axios.post(route('cart.add'), item).then((response) => {
                    this.setContents(response.data);
                    return response;
                }),
                [item],
            );
        },
        async addItems(items) {
            for (const item of items) {
                await this.addItem(item);
            }
        },
        async applyDiscount(code) {
            await this.process(
                'applyDiscount',
                axios.patch(route('cart.applyDiscount'), { code }).then((response) => {
                    this.setContents(response.data);
                    return response;
                }),
                [code],
            );
        },
        async removeDiscount(code) {
            await this.process(
                'removeDiscount',
                axios.patch(route('cart.removeDiscount'), { code }).then((response) => {
                    this.setContents(response.data);
                    return response;
                }),
                [code],
            );
        },
        async applyTip(amount, type) {
            await this.process(
                'applyTip',
                axios.patch(route('cart.applyTip'), { amount, type }).then((response) => {
                    this.setContents(response.data);
                    return response;
                }),
                [amount, type],
            );
        },
        async addDeliveryFee(amount) {
            await this.process(
                'addDeliveryFee',
                axios.patch(route('cart.addDeliveryFee'), { amount }).then((response) => {
                    this.setContents(response.data);
                    return response;
                }),
                [amount],
            );
        },
        async removeDeliveryFee() {
            await this.process(
                'removeDeliveryFee',
                axios.patch(route('cart.removeDeliveryFee')).then((response) => {
                    this.setContents(response.data);
                    return response;
                }),
            );
        },
        getItem(itemIdentifier) {
            return get(this.contents, ['items', itemIdentifier].join('.'));
        },
        getEditHref(item) {
            return item.meta.menu_item.href;
        },
        setContents(contents) {
            this.contents = contents;
            this.contents.items = keyBy(Object.values(get(contents, 'items', [])), 'uuid');
            if (this.contents.redirect) {
                window.location = this.contents.redirect;
            }
        },
        async fetchItem(itemIdentifier) {
            await this.process(
                'fetchItem',
                axios.get(route('cart.edit', { item: itemIdentifier })).then((response) => {
                    return response.data;
                }),
                [itemIdentifier],
            );
        },
        async updateItem(itemIdentifier, item = {}) {
            console.log(itemIdentifier, item, 'zingding');
            await this.process(
                'updateItem',
                axios
                    .patch(route('cart.update', { item: itemIdentifier }), item)
                    .then((response) => {
                        return response.data;
                    }),
                [itemIdentifier, item],
            );
        },
        async addName(itemIdentifier, name = null) {
            try {
                await this.process(
                    'addName',
                    axios
                        .patch(route('cart.addName', { item: itemIdentifier }), {
                            name,
                        })
                        .then((response) => {
                            return response.data;
                        }),
                    [itemIdentifier, name],
                );
                return true;
            } catch (error) {
                return false;
            }
        },
        async increaseQuantity(itemIdentifier, amount = 1) {
            await this.process(
                'increaseQuantity',
                axios
                    .patch(route('cart.more', { item: itemIdentifier }), {
                        amount,
                    })
                    .then((response) => {
                        return response.data;
                    }),
                [itemIdentifier, amount],
            );
        },
        async decreaseQuantity(itemIdentifier, amount = 1) {
            await this.process(
                'decreaseQuantity',
                axios
                    .patch(route('cart.less', { item: itemIdentifier }), {
                        amount,
                    })
                    .then((response) => {
                        return response.data;
                    }),
                [itemIdentifier, amount],
            );
        },
        async saveQuantity(itemIdentifier, amount = 1) {
            await this.process(
                'setQuantity',
                axios
                    .patch(route('cart.quantity', { item: itemIdentifier }), {
                        amount,
                    })
                    .then((response) => {
                        this.setContents(response.data);
                        this.bus.emit('quantityUpdated');
                        return response;
                    }),
                [itemIdentifier, amount],
            );
        },
        async setQuantity(itemIdentifier, amount = 1) {
            const value = Math.max(amount, 0);
            this.contents.items[itemIdentifier].quantity = value;
            await this.debounce('saveQuantity', 300, [itemIdentifier, value]);
        },
        async updateItemQuantity(item) {
            return this.setQuantity(item.uuid, item.quantity);
        },
        async removeItemById(itemIdentifier) {
            this.bus.emit('removedItem', itemIdentifier);
            await this.process(
                'removeItem',
                axios.delete(route('cart.remove', { item: itemIdentifier })).then((response) => {
                    this.setContents(response.data);
                    return response;
                }),
                [itemIdentifier],
            );
        },
        async removeItem(item) {
            return this.removeItemById(item.uuid);
        },
        async reset() {
            await this.process(
                'reset',
                axios.patch(route('cart.reset')).then((response) => {
                    this.setContents(response.data);
                    return response;
                }),
            );
        },
        getCartAdjustmentPrice(tag) {
            return get(
                this.summary,
                ['adjustments', tag, 'amount.formatted'].join('.'),
                formatCurrency(0),
            );
        },
        getCartAdjustmentLabel(tag, defaultLabel = null) {
            return get(this.summary, ['adjustments', tag, 'label'].join('.'), defaultLabel);
        },
    },
});
