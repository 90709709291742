<script setup>
import AppIcon from '@/customer/Components/AppIcon.vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { useCartStore } from '@/customer/Composables';
import { ref } from 'vue';
import CartHeader from './CartHeader.vue';
import CartContents from './CartContents.vue';
import CartFooter from './CartFooter.vue';
import CartBanner from './CartBanner.vue';

const cart = useCartStore();
cart.initialize();

// used to gracefully load CartFooter, b/c CartAddons jumpy
const open = ref(false);

const close = () => {
    cart.close();
};

const scrollableArea = ref(null);

cart.bus.on('scrollToBottom', () => {
    scrollableArea.value.scrollTop = scrollableArea.value.scrollHeight;
});

</script>

<template>
    <button class="group flex items-center" @click="cart.open">
        <div class="relative">
            <div
                class="relative flex flex-col content-center items-center justify-center border-transparent px-1 pt-1 text-sm font-bold leading-5 tracking-widest text-black transition duration-150 ease-in-out"
            >
                <AppIcon
                    icon="BagIcon"
                    class="mb-1.5 size-7 sm:absolute sm:-top-5 sm:left-1/2 sm:mb-0 sm:size-5 sm:-translate-x-1/2 sm:transform"
                />
                <p class="hidden sm:block">MY ORDER</p>
            </div>
            <div
                v-if="!cart.isEmpty"
                class="absolute -top-0 right-5 flex h-4 items-center rounded-full bg-palmers-avocado-green p-1 text-sm font-bold text-black sm:-top-6 sm:right-7"
            >
                {{ cart.count }}
            </div>
        </div>

        <span class="sr-only">items in cart</span>
    </button>

    <TransitionRoot as="template" :show="cart.isOpen">
        <Dialog as="div" class="relative z-[10000]" @close="close">
            <TransitionChild
                as="template"
                enter="ease-in-out duration-500"
                enter-from="opacity-0"
                enter-to="opacity-100"
                @before-enter="open = true"
                leave="ease-in-out duration-500"
                leave-from="opacity-100"
                leave-to="opacity-0"
                @after-leave="open = false"
            >
                <div class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 select-none overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 ml-10 flex max-w-full">
                        <TransitionChild
                            as="template"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="translate-x-full"
                            enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from="translate-x-0"
                            leave-to="translate-x-full"
                        >
                            <DialogPanel class="pointer-events-auto w-screen max-w-screen-md">
                                <div
                                    ref="scrollableArea"
                                    class="flex h-screen flex-col scroll-smooth bg-white shadow-xl"
                                >
                                    <div class="grow overflow-y-auto max-h-[calc(100vh-310px)] sm:pb-0 pb-16">
                                        <CartBanner />
                                        <div class="flex-1">
                                            <CartHeader />
                                            <CartContents />
                                        </div>
                                    </div>
                                    <template v-if="!cart.isEmpty && open">
                                        <CartFooter />
                                    </template>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
