import { isEqual } from 'lodash';

export const RadioOptionProps = {
    modelValue: {
        default: false,
        type: [Boolean, Number],
    },
    value: {
        default: false,
        type: [Boolean, Number],
    },
    option: {
        default: {},
        type: Object,
    },
    name: {
        type: String,
        default: null,
    },
    label: {
        type: [String, Boolean],
        default: null,
    },
    help: {
        type: String,
        default: null,
    },
    description: {
        type: String,
        default: null,
    },
    inputClass: {
        type: String,
        default: null,
    },
    labelClass: {
        type: String,
        default: null,
    },
    checked: {
        type: [Array, Boolean],
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    tabindex: {
        type: [String, Number],
        default: '0',
    },
    as: {
        type: [Object, String],
        default: 'radio',
        required: false,
    },
    errorMessage: {
        type: String,
        default: null,
    },
};

export const RadioGroupProps = {
    by: {
        type: Function,
        default: isEqual,
    },
    modelValue: {
        type: [Object, Array],
        default: () => [],
    },
    defaultValue: {
        type: [Object, Array],
        default: () => [],
    },
    label: {
        type: [String, Boolean],
        default: null,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    options: {
        type: Array,
        default: () => [],
    },
    name: { type: String, default: 'radio' },
    labelKey: { type: String, default: 'label' },
    valueKey: { type: String, default: 'value' },
    as: {
        type: [Object, String],
        default: 'checkbox',
        required: false,
    },
    wrapperClass: {
        type: [String, Function],
        default: '',
    },
    itemClass: {
        type: [String, Function],
        default: '',
    },
};

export const CheckboxProps = {
    modelValue: {
        default: false,
        type: [Boolean, Number],
    },
    value: {
        default: false,
        type: [Boolean, Number],
    },
    option: {
        default: {},
        type: Object,
    },
    label: {
        type: [String, Boolean],
        default: null,
    },
    help: {
        type: String,
        default: null,
    },
    description: {
        type: String,
        default: null,
    },
    inputClass: {
        type: String,
        default: null,
    },
    labelClass: {
        type: String,
        default: null,
    },
    checked: {
        type: [Boolean, Array],
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    tabindex: {
        type: [String, Number],
        default: '0',
    },
    as: {
        type: [Object, String],
        default: 'checkbox',
        required: false,
    },
    errorMessage: {
        type: String,
        default: null,
    },
    name: {
        default: null,
    },
};

export const CheckboxOptionProps = RadioGroupProps;
export const CheckboxGroupProps = RadioGroupProps;
