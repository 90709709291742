<script setup>
import { computed, watch, ref, onMounted } from 'vue';
import { Head, useForm } from '@inertiajs/vue3';
import { ListView, GridView } from './Partials';
import { EmptyBoundary } from '@/common/Components';
import { useMenuItemStore, useOrderStore } from '@/customer/Composables';

const props = defineProps({
    menuItem: { type: Object },
});

const $store = useMenuItemStore();
const orderStore = useOrderStore();
const menuItem = ref(props.menuItem);

const innerConfigQuantities = props.menuItem.innerConfigQuantities ?? $store.innerConfigQuantities;
const form = useForm({
    slug: props.menuItem.slug,
    name: null,
    instructions: null,
    quantities: $store.quantities,
    size: props.menuItem.size,
    innerConfigQuantities: props.menuItem.type == 'combination' ? innerConfigQuantities : null,
    addedPriceForMinConfigs: 0,
});

const requiredConfigurations = computed(() => {
    if (
        props.menuItem?.type == 'configurable' ||
        props.menuItem?.type == 'preConfigured' ||
        props.menuItem?.type == 'schedule'
    ) {
        return props.menuItem?.configurations.filter((config) => config.required);
    } else if (props.menuItem?.type == 'combination') {
        return props.menuItem?.comboItemOptions.filter((option) => option.required);
    } else {
        return [];
    }
});

const requiredConfigurationIds = requiredConfigurations.value.map((config) => config.id);

$store.attachForm(form);
$store.initializeMenuItem(props.menuItem, orderStore.selectedLocation?.id);

const price = computed(() => {
    let total = parseFloat(props.menuItem.price.raw);
    return total.toFixed(2);
});

const LayoutComponent = computed(
    () =>
        ({
            'list-view': ListView,
            'grid-view': GridView,
        })[props.menuItem.layout],
);

const switched = ref(false);

// if editing
onMounted(() => {
    if ($store.quantities) {
        const configChoiceChange = [];
        if (props.menuItem?.type == 'combination') {
            Object.keys($store.quantities).forEach((key) => {
                if (typeof $store.quantities[key] === 'object') {
                    Object.keys($store.quantities[key]).forEach((choice) => {
                        props.menuItem?.comboItemOptions?.forEach((comboItemOption) => {
                            comboItemOption.items.forEach((item) => {
                                if (choice == item.id) {
                                    if (
                                        item.choice.type !== 'schedule' &&
                                        item.choice.type !== 'simple'
                                    ) {
                                        console.log(item.choice);
                                        fetchSpecificInnerConfig(props.menuItem.id, choice);
                                    }
                                }
                            });
                        });
                    });
                }
            });
        }
    }
});
watch(
    () => $store.quantities,
    (newQuantities) => {
        const configChoiceChange = [];
        if (props.menuItem?.type == 'combination') {
            Object.keys(newQuantities).forEach((key) => {
                if (typeof newQuantities[key] === 'object') {
                    Object.keys(newQuantities[key]).forEach((choice) => {
                        props.menuItem?.comboItemOptions?.forEach((comboItemOption) => {
                            comboItemOption.items.forEach((item) => {
                                if (choice == item.id) {
                                    if (item.choice.type !== 'schedule') {
                                        fetchSpecificInnerConfig(props.menuItem.id, choice);
                                    }
                                }
                            });
                        });
                        if ($store.configurableChoices.includes(choice)) {
                            if (props.menuItem.innerConfigQuantities !== 'null') {
                                configChoiceChange.push(choice);
                            }
                        }
                    });
                }
            });

            if (props.menuItem.innerConfigQuantities !== 'null') {
                if (configChoiceChange[0] !== Object.keys($store.innerConfigQuantities)[0]) {
                    if (switched.value == false) {
                        console.log('reverting to default inner config quantities');
                        $store.defaultInnerConfigQuantities(orderStore.selectedLocation?.id);
                        switched.value = true;
                    }
                }
            }
        }
    },
    { deep: true },
);

const allConfigs = ref();

function adjustConfigs(configs, choiceId) {
    menuItem.value.comboItemOptions.map((comboItemOption) => {
        comboItemOption.items.map((item) => {
            if (item.id == choiceId) {
                configs.forEach((config) => {
                    config.choices = config.choices.filter((choice) => {
                        if (choice.activeLocations) {
                            if (!choice.activeLocations.includes(orderStore.selectedLocation?.id)) {
                                return false;
                            }
                        }
                        return true;
                    });
                });

                item.item.configurations = configs.filter((i) => i.item_id == item.item.item_id);
            }
        });
    });
}

async function fetchSpecificInnerConfig(menuItemId, choiceId) {
    try {
        const response = await fetch(`/menu-items/${menuItemId}/inner-configurations/${choiceId}`);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        // console.log(data);
        allConfigs.value = data;
        adjustConfigs(data, choiceId);
    } catch (error) {
        console.error('Error fetching configurations:', error);
    }
}
</script>

<template>
    <Head :title="`Menu Item`" />
    <div class="mx-auto max-w-screen-lg" id="menuItem">
        <EmptyBoundary v-model="LayoutComponent" message="Unknown layout type">
            <LayoutComponent
                :menuItem="menuItem"
                :price="price"
                :requiredSelections="requiredConfigurationIds"
                v-model:selections="$store.selectedChoices"
                v-model:quantities="form.quantities"
            />
        </EmptyBoundary>
    </div>
</template>
