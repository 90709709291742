<script setup>
import { InputGroup, CheckboxGroupInputSingle } from '@/customer/Components';
import { CreditCardForm, ContactInfoForm, PaymentProfileOption } from '.';
import { usePage } from '@inertiajs/vue3';
import { computed, ref } from 'vue';
import { useCheckoutStore, useAuthNetStore } from '@/customer/Composables';

const authnet = useAuthNetStore();
const paymentProfiles = computed(() => usePage().props.paymentProfiles?.data);
const selections = ref([]);

console.log({ paymentProfiles });

const handleSelections = ($event) => {
    selections.value = $event;
    authnet.responseData.paymentProfileId = $event[0];
};
</script>
<template>
    <div class="space-y-2">
        <div class="text-xl font-bold text-palmers-charcoal-grey">Saved Payment Methods</div>
        <InputGroup
            :label="null"
            :as="PaymentProfileOption"
            :type="CheckboxGroupInputSingle"
            :options="paymentProfiles"
            value-key="customerPaymentProfileId"
            :model-value="selections"
            @update:model-value="handleSelections"
            wrapperClass="grid grid-cols-2 sm:grid-cols-3 gap-2"
        />
    </div>
</template>
