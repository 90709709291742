<script setup>
import { ref, onMounted } from 'vue';
import { useCheckoutStore } from '@/customer/Composables';
import { Spinner } from '@/common/Components';

const cartItems = ref(null);
const checkout = useCheckoutStore();
checkout.initialize();
</script>

<template>
    <div class="grid grid-cols-1 xl:grid-cols-12">
        <div class="col-span-7 flex justify-start text-white xl:w-auto">
            <div
                v-if="checkout.processing"
                class="absolute bottom-0 right-0 top-0 z-20 w-screen cursor-wait bg-black opacity-50"
            ></div>
            <div class="overflow-auto bg-palmers-dragon-fruit px-3 sm:px-6 md:w-[100vw] md:px-16">
                <div class="z-10 py-6 md:py-12">
                    <slot name="form" />
                </div>
            </div>
        </div>
        <div
            class="relative inset-0 col-span-5 h-full overflow-y-auto xl:sticky xl:top-[6.5rem] xl:h-[calc(100vh-6.5rem)]"
            ref="cartItems"
        >
            <slot name="cart" />
            <slot name="addons" />
        </div>
        <div
            v-if="checkout.processing"
            class="absolute bottom-0 left-0 top-0 z-20 w-screen cursor-wait bg-black opacity-50"
        />
        <div
            class="absolute inset-0 z-20 flex cursor-wait items-center justify-center text-palmers-dragon-fruit"
            v-if="checkout.processing"
        >
            <div class="space-y-4">
                <!-- <span>We're processing your order</span> -->
                <Spinner class="mx-auto h-24 w-24" />
            </div>
        </div>
    </div>
</template>
