<script setup>
import { ref, onMounted, onBeforeMount, computed } from 'vue';
import { Head, usePage } from '@inertiajs/vue3';

import { CheckCircleIcon } from '@heroicons/vue/24/outline';

import CompanyMessage from './Partials/CompanyMessage.vue';
import OrderConfiguration from './Partials/OrderConfiguration.vue';
import ItemsOverview from './Partials/ItemsOverview.vue';
import { useCartStore } from '@/customer/Composables';

const pageProps = usePage().props;
const order = ref(pageProps.order);
const cartStore = useCartStore();

onBeforeMount(() => {
    cartStore.close();
});

const isCatering = computed(() => order.value.menu_type == 'catering');

const fulfillmentDisplay = ref(
    order.value.fulfillment_type === 'carryout' ? 'In-Store' : 'Delivery',
);

const locations = ref(pageProps.app.locations);

const itemOverviewContainer = ref(null);
const itemOverviewHeight = ref(null);

const getHeight = () => {
    itemOverviewHeight.value = itemOverviewContainer.value.getBoundingClientRect().height;
};

onMounted(() => getHeight());
</script>

<template>
    <Head :title="isCatering ? 'Inquiry Placed' : 'Order Placed'" />

    <div
        class="relative w-screen -translate-x-[1rem] overflow-hidden border-palmers-avocado-light bg-palmers-avocado-light/20 sm:my-6 sm:w-full sm:-translate-x-0 sm:rounded-3xl sm:border-2 md:my-0"
    >
        <!-- Header -->
        <div
            class="flex w-full items-center justify-center gap-x-2 border-b-2 border-palmers-avocado-light bg-palmers-avocado-light/40 px-6 pb-3 pt-4 sm:pt-3"
        >
            <CheckCircleIcon class="size-6 stroke-[2] text-palmers-charcoal-grey/80" />
            <p
                class="text-center text-sm font-bold uppercase text-palmers-charcoal-grey/80 sm:text-lg"
            >
                Thank you for your
                {{ isCatering ? 'catering inquiry' : 'order' }},
                {{ order.contact.first_name }}
            </p>
        </div>

        <!-- Main Section -->
        <div
            class="grid h-full grid-cols-2 gap-x-6 pt-6 sm:gap-y-6 sm:p-6 [@media(width<1210px)]:grid-cols-1"
        >
            <!-- Left Section -->
            <div class="relative z-20 h-full items-center space-y-6">
                <CompanyMessage />

                <OrderConfiguration
                    v-if="itemOverviewHeight > 934"
                    :order="order"
                    :locations="locations"
                    :fulfillmentDisplay="fulfillmentDisplay"
                    :is-catering="isCatering"
                />
            </div>

            <!-- Right Section -->
            <div class="flex w-full flex-col items-center sm:gap-y-6">
                <OrderConfiguration
                    v-if="itemOverviewHeight <= 934"
                    :order="order"
                    :locations="locations"
                    :fulfillmentDisplay="fulfillmentDisplay"
                    :is-catering="isCatering"
                />

                <div ref="itemOverviewContainer" class="w-full">
                    <ItemsOverview
                        :order="order"
                        :fulfillmentDisplay="fulfillmentDisplay"
                        :is-catering="isCatering"
                    />
                </div>
            </div>
        </div>
        <!-- <div
            class="absolute -left-4 bottom-[2.5rem] z-10 flex w-[70%] rotate-[0deg] flex-col gap-y-1 [@media(width<1210px)]:hidden"
        >
            <div class="h-[10px] bg-palmers-avocado-green" />
            <div class="h-[9px] bg-palmers-avocado-light" />
            <div class="h-[8px] bg-palmers-dragon-fruit" />
        </div> -->
    </div>
</template>
