<script setup>
import { computed, ref } from 'vue';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/vue/24/solid';
import AddOnItem from '@/customer/Components/Cart/AddOnItem.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

const isCheckout = computed(() => route().current() === 'customer.checkout.index');

const props = defineProps({
    items: { type: Object, required: true },
    label: { type: String, default: null },
});

const swiper = ref(null);
const onSwiper = (swipe) => {
    swiper.value = swipe;
};

const addOnRef = ref(null);

const thisWidth = computed(() => {
    if (!addOnRef.value) {
        return 0;
    }

    return addOnRef.value.getBoundingClientRect().width;
});

const columnClass = ref(null);

const isSwiper = computed(() => {
    if (thisWidth.value === 0) {
        return false;
    }

    const width = thisWidth.value;
    const breakpoints = [
        {
            flag: width < 360,
            length: 1,
        },
        {
            flag: width >= 360 && width < 575,
            length: 2,
        },
        {
            flag: width >= 575 && width < 760,
            length: 3,
        },
        {
            flag: width >= 760,
            length: 4,
        },
    ];

    const matchingBreakpoint = breakpoints.find((bp) => bp.flag);

    if (matchingBreakpoint) {
        if (props.items.length > matchingBreakpoint.length) {
            return true;
        } else {
            columnClass.value = `grid-cols-${props.items.length}`;
        }
    }

    return false;
});
</script>

<template>
    <div ref="addOnRef" class="text-palmers-charcoal-grey">
        <div
            class="flex w-full max-w-3xl justify-between bg-palmers-havarti px-6 pb-1 pt-4"
            :class="[
                isCheckout
                    ? 'sm:px-10'
                    : 'fixed -translate-y-[100%] transition-all ease-in-out sm:translate-y-0 sm:px-20 sm:opacity-0 sm:group-hover:-translate-y-[100%] sm:group-hover:opacity-100',
            ]"
        >
            <h3 class="text-2xl font-bold">
                {{ label }}
            </h3>
            <div v-if="isSwiper" class="flex space-x-6">
                <ArrowLeftIcon
                    class="h-8 w-8 cursor-pointer stroke-palmers-charcoal-grey stroke-2"
                    @click="swiper.slidePrev()"
                />
                <ArrowRightIcon
                    class="h-8 w-8 cursor-pointer stroke-palmers-charcoal-grey stroke-2"
                    @click="swiper.slideNext()"
                />
            </div>
        </div>
        <div class="bg-palmers-havarti px-4 pb-2 sm:px-14 sm:pb-5 sm:pt-2">
            <Swiper
                :slides-per-view="1"
                @swiper="onSwiper"
                :breakpoints="{
                    360: {
                        slidesPerView: 2,
                    },
                    575: {
                        slidesPerView: 3,
                    },
                    760: {
                        slidesPerView: 4,
                    },
                }"
            >
                <SwiperSlide
                    v-if="isSwiper"
                    class="my-2 sm:mb-0 sm:mt-3"
                    v-for="item in items"
                    :key="item.id"
                >
                    <AddOnItem :item="item" as="a" />
                </SwiperSlide>
                <div v-else class="grid" :class="columnClass">
                    <div v-for="item in items" :key="item.id">
                        <AddOnItem :item="item" as="a" />
                    </div>
                </div>
            </Swiper>
        </div>
    </div>
</template>
