<script setup>
import { onMounted, ref } from 'vue';
import { vMaska } from 'maska';

const props = defineProps({
    modelValue: {
        type: [String, Number, null],
        required: true,
        default: '',
    },
    name: {
        type: String,
        default: null,
    },
    maskOptions: {
        type: [Object, Boolean],
        default: false,
    },
    autocomplete: {
        type: String,
        required: false,
    },
});

defineEmits(['update:modelValue', 'focus', 'blur', 'input', 'change', 'keyup', 'keydown', 'maska']);

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({
    get input() {
        return input.value;
    },
    focus: () => input.value.focus(),
});
</script>

<template>
    <input
        v-bind="{ ...$attrs }"
        ref="input"
        v-maska:[$props.maskOptions]
        class="w-full rounded-full border-2 border-palmers-dragon-fruit bg-white px-6 py-3 text-base tracking-widest text-black focus:border-palmers-dragon-fruit focus:ring-palmers-dragon-fruit focus:ring-offset-2"
        :value="modelValue"
        @keyup="$emit('keyup', $event)"
        @blur="$emit('blur', $event)"
        @input="$emit('update:modelValue', $event.target.value)"
        @maska="$emit('maska', $event)"
        :autocomplete="autocomplete"
    />
</template>
