<script setup>
import OrderProgressBanner from './OrderProgressBanner.vue';
import NewSystemDisclaimer from './NewSystemDisclaimer.vue';
import { inject, computed } from 'vue';
import { orderState } from '@/customer/Utils/keys';
import { usePage } from '@inertiajs/vue3';

const order = inject(orderState);
const showBanner = computed(() => order.isStarted && !usePage().props.cart);
const isLogin = computed(() => route().current() === 'customer.login');
</script>

<template>
    <section id="layout-banners" class="pt-0">
        <OrderProgressBanner v-if="showBanner && !isLogin" />
        <NewSystemDisclaimer v-if="isLogin" />
    </section>
</template>
