<script setup>
import ShoppingStoreIcon from '@/customer/Components/Icons/ShoppingStoreIcon.vue';
import { RadioGroup, RadioGroupOption } from '@headlessui/vue';
import { computed } from 'vue';
import { DevicePhoneMobileIcon } from '@heroicons/vue/20/solid';
import InputError from '@/customer/Components/InputError.vue';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    modelValue: {
        type: String,
        default: null,
    },
    locations: {
        type: [Array, Object],
        default: () => [],
    },
    errorMessage: String,
});

const showLocation = (showComingSoon) => {

    if (showComingSoon) {
        return false;
    }

    return true;
};

const selection = computed({
    get: () => (props.modelValue),
    set(value) {
        emit('update:modelValue', value);
    },
});
</script>

<template>
    <RadioGroup v-model="selection">
        <ul class="grid grid-cols-2 gap-4" v-if="locations?.length ?? false">
            <RadioGroupOption
                v-for="(location, index) in locations"
                :key="`location${index}`"
                :value="location.slug"
                :tab-index="showLocation(location.show_coming_soon) ? 1 : -1"
                as="template"
                v-slot="{ active, checked }"
            >
                <li
                    class="group relative flex cursor-pointer flex-row items-center overflow-hidden rounded-xl border px-3 py-3 transition-colors [@media(width>610px)]:space-x-6 [@media(width>610px)]:px-6"
                    :class="[
                        checked
                            ? 'border-transparent bg-palmers-havarti shadow-none ring-4 ring-palmers-dragon-fruit'
                            : 'bg-white supports-hover:hover:border-transparent supports-hover:hover:bg-palmers-havarti supports-hover:hover:bg-opacity-30 supports-hover:hover:ring-4 supports-hover:hover:ring-palmers-himalayan-pink',
                        showLocation(location.show_coming_soon) ? 'shadow-md ' : 'pointer-events-none',
                    ]"
                >
                    <ShoppingStoreIcon
                        :class="{ 'opacity-30': !showLocation(location.show_coming_soon) }"
                        class="hidden h-14 w-14 [@media(width>610px)]:block"
                    />

                    <div :class="{ 'opacity-30': !showLocation(location.show_coming_soon) }">
                        <h3 class="text-lg font-semibold leading-[1.3rem] md:text-base">
                            {{ location.name }}
                        </h3>

                        <address class="text-xs not-italic leading-tight" v-if="location.address">
                            <span> {{ location.address.street }} </span><br />
                            <template v-if="location.address.street2">
                                <span> {{ location.address.street2 }} </span><br />
                            </template>
                            <span class="leading-none">
                                {{ location.address.city }},
                                {{ location.address.state }}
                                {{ location.address.zip }}
                            </span>
                        </address>

                        <a
                            v-if="location.address"
                            :href="`tel:${location.address.phone}`"
                            class="mt-1 inline-flex items-center space-x-1 text-sm underline md:pointer-events-none md:cursor-auto md:no-underline"
                        >
                            <DevicePhoneMobileIcon class="h-6 w-4" />
                            <span>{{ location.address.phoneDisplay }}</span>
                        </a>
                    </div>
                    <div
                        v-if="!showLocation(location.show_coming_soon)"
                        class="absolute inset-0 flex sm:w-[calc(100%+1rem)] w-[calc(100%+2rem)] -translate-x-6 items-center justify-center bg-palmers-charcoal-grey/30"
                    >
                        <p
                            class="-translate-y-[1px] rotate-[0deg] rounded-md px-2 py-1 lg:text-3xl md:text-xl text-md font-bold uppercase text-white"
                        >
                            Coming Soon
                        </p>
                    </div>
                </li>
            </RadioGroupOption>
        </ul>
        <InputError v-if="errorMessage" :message="errorMessage" class="text-center" />
    </RadioGroup>
</template>
