<script setup>
import { useStorage } from '@vueuse/core';
import XCloseIcon from '../Icons/XCloseIcon.vue';

const showLoginDisclaimer = useStorage('new-ordering-system-disclaimer', true);
</script>

<template>
    <Transition
        enter-from-class="-translate-y-[100%] opacity-0"
        leave-to-class="-translate-y-[100%] opacity-0"
        enter-active-class="transition duration-300"
        leave-active-class="transition duration-300"
    >
        <div
            v-if="showLoginDisclaimer"
            class="relative w-full bg-palmers-dragon-fruit p-4 text-white [@media(width>710px)]:text-center"
        >
            <div
                class="mx-auto flex max-w-7xl flex-row items-start justify-between gap-x-5 [@media(width>710px)]:flex-col [@media(width>710px)]:items-center"
            >
                <div>
                    <p class="text-lg font-bold">
                        Welcome to our brand-new online ordering system!
                    </p>
                    <p>
                        If you're a returning customer from our old system, please re-register to
                        continue using our services.
                    </p>
                </div>
                <button
                    @click="showLoginDisclaimer = false"
                    class="inset-y-0 right-4 translate-y-1 hover:opacity-80 [@media(width>710px)]:absolute"
                >
                    <XCloseIcon class="size-7" />
                </button>
            </div>
        </div>
    </Transition>
</template>
