<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useCartStore, useOrderStore } from '@/customer/Composables';
import { MenuType } from '@/common/Utils/enums.js';
import CartAddons from './CartAddons.vue';
import { Tooltip } from '@/customer/Components';
import SpinnerIcon from '../Icons/SpinnerIcon.vue';
import moment from 'moment';
import { router } from '@inertiajs/vue3';

const cart = useCartStore();
const order = useOrderStore();

const checkoutLoading = ref(false);
const readyAtDay = computed(() => moment(order.form.ready_at).day())
const hasInvalidMessages = computed(() => {
    if (cart.items && typeof cart.items === 'object') {
        const itemsArray = Object.values(cart.items);
        const hasInvalidMessage = itemsArray.some((item) => item.unavailable_message !== null);
        return hasInvalidMessage;
    }
    return false;
});


watch(
    () => readyAtDay.value,
    () => {
        cart.fetchCart();
    }
);

const checkoutClick = () => {
    checkoutLoading.value = true;
    router.visit(route('customer.checkout.index'), {
        onSuccess: () => {
            checkoutLoading.value = false;
        }
    })
}
</script>

<template>
    <div class="group z-10 w-screen max-w-3xl bg-palmers-havarti absolute bottom-0">
        <template v-if="!order.isCatering">
            <CartAddons />
        </template>

        <!-- Summary -->
        <div
            v-if="!order.isCatering"
            class="border-t-2 bg-palmers-white p-5 text-palmers-charcoal-grey sm:px-20"
        >
            <div
                class="flex flex-wrap items-center justify-between text-2xl font-bold leading-none"
            >
                <h3>SUBTOTAL</h3>
                <div class="sm:text-4xl">
                    {{ cart.summary.subtotal.formatted }}
                </div>
            </div>
        </div>

        <!-- Phase 2 Catering -->
        <div v-if="false">
            <div
                class="space-y-4 bg-palmers-havarti px-10 py-8 text-palmers-charcoal-grey sm:px-20"
            >
                <div class="flex items-center justify-between gap-x-2">
                    <h3 class="text-4xl font-bold">Quote:</h3>
                    <div class="text-5xl font-bold">{{ cart.summary.subtotal.formatted }}</div>
                </div>
                <p class="text-sm">
                    This is our best estimate for your order before tax and tip. Upon future
                    communications, we will provide a final cost.
                </p>
            </div>
        </div>

        <!-- Actions -->
        <div class="z-40 flex w-auto">
            <div
                class="item-center flex w-full flex-wrap justify-between gap-y-4 bg-palmers-dragon-fruit p-4 text-palmers-charcoal-grey sm:px-20"
            >
                <a
                    :href="
                        route('customer.menu.index', {
                            type: MenuType.MAIN,
                        })
                    "
                    class="w-50 rounded-full border-2 border-palmers-charcoal-grey bg-white px-4 py-3 text-center text-xl font-bold supports-hover:hover:bg-palmers-avocado-green sm:w-auto sm:py-2"
                >
                    {{ order.isCatering ? 'NEED MORE?' : 'STILL HUNGRY?' }}
                </a>
                <Tooltip
                    v-if="hasInvalidMessages"
                    message="Please check your cart items."
                    class="flex w-50 items-center justify-center sm:mb-0 sm:w-auto"
                >
                    <a
                        href="#"
                        class="w-50 cursor-not-allowed rounded-full border-2 border-black bg-white px-4 py-3 text-center text-xl font-bold sm:w-auto sm:py-2"
                    >
                        CHECKOUT
                    </a>
                </Tooltip>
                <Tooltip
                    v-else-if="
                        order.isDelivery && !order.isCatering && cart.summary.subtotal.raw < 60
                    "
                    message="Deliveries must be at least $60"
                    class="flex w-50 items-center justify-center sm:mb-0 sm:w-auto"
                >
                    <a
                        href="#"
                        class="w-full cursor-not-allowed rounded-full border-2 border-black bg-white px-4 py-3 text-center text-xl font-bold sm:w-auto sm:py-2"
                    >
                        CHECKOUT 
                    </a>
                </Tooltip>
                <a
                    v-else
                    @click="checkoutClick()"
                    class="relative flex w-50 items-center justify-center rounded-full border-2 border-black bg-white px-4 py-3 text-center text-xl font-bold supports-hover:hover:bg-palmers-avocado-green sm:mb-0 sm:w-auto sm:py-2"
                >
                    <span :class="{ 'opacity-0': checkoutLoading }">{{
                        order.isCatering ? 'PLACE INQUIRY' : 'CHECKOUT'
                    }}</span>
                    <SpinnerIcon
                        :class="{ hidden: !checkoutLoading }"
                        class="absolute size-7 animate-spin text-palmers-dragon-fruit"
                    />
                </a>
            </div>
        </div>
    </div>
</template>
