<script setup>
import { Link } from '@inertiajs/vue3';
import { PhotoIcon } from '@heroicons/vue/24/solid';
import { useMenuItemStore, useCartStore } from '@/customer/Composables';

defineProps({
    item: { type: Object, required: true },
    as: { type: String, default: 'div' },
});

const menuItemStore = useMenuItemStore();
const cartStore = useCartStore();

const addSimpleItemToCart = async (item) => {
    const addToCartObj = formatSimpleCartObject(item, 1);
    await menuItemStore.addToCart(1, addToCartObj);
    cartStore.open();
    cartStore.scrollToBottom();
};

const formatSimpleCartObject = (item, quantity = null) => {
    return {
        innerConfigQuantities: [],
        instructions: null,
        name: null,
        quantities: [],
        size: item.size_id,
        slug: item.slug,
        quantity: quantity,
        single_size: true,
    };
};
</script>

<template>
    <component
        :is="as"
        class="flex cursor-pointer flex-row items-center gap-x-2 px-2 [@media(width>575px)]:flex-col [@media(width>575px)]:px-0"
        @click="addSimpleItemToCart(item)"
    >
        <img class="!size-20" v-if="item.image" :src="item.image.original" />
        <PhotoIcon class="!size-20 text-gray-500 opacity-50" v-else />
        <div
            class="text-sm text-palmers-charcoal-grey [@media(width>575px)]:px-2 [@media(width>575px)]:text-center"
        >
            <h3 class="font-bold">
                {{ item.name }}
            </h3>
            <p>${{ item.cost }}</p>
        </div>
    </component>
</template>
