<script setup>
import { computed, ref } from 'vue';
const emit = defineEmits(['update:modelValue']);
const props = defineProps({
    value: {
        type: Object,
        default: null,
    },
    options: {
        type: Array,
        default: null,
    },
    placeholder: {
        type: String,
        default: 'Select...',
    },
    customClass: {
        type: String,
        default: '',
    },
});

const extendedOptions = computed(() => [
    { id: 'placeholder', label: props.placeholder, value: null },
    ...props.options,
]);

const selected = ref(!!props.value);

const selection = computed(
    () =>
        extendedOptions.value.find((item) => item.number === props.value) ??
        extendedOptions.value[0],
);

const handleChange = (value) => {
    console.log(value);
    selected.value = !(value === 'placeholder');
    emit('update', value === 'placeholder' ? null : value);
};
</script>

<template>
    <select
        :value="selection.id"
        @change="(e) => handleChange(e.target.value)"
        class="block w-full rounded-3xl border-2 border-palmers-dragon-fruit px-5 focus:border-palmers-dragon-fruit focus:ring-0"
        :class="[customClass, selected ? '!text-black' : '!text-palmers-mush-grey']"
    >
        <template v-for="({ id, label }, index) in extendedOptions" :key="index">
            <option :value="id">{{ label }}</option>
        </template>
    </select>
</template>

<style>
option[value=''][disabled] {
    display: none;
    color: red;
}
</style>
