<script setup>
import { computed, ref, onMounted } from 'vue';
import OptionPicker from './OptionPicker.vue';
import { useForm } from '@inertiajs/vue3';
import DatePicker from '@/customer/Components/UI/Input/Date/DatePicker.vue';
import TimePicker from '@/customer/Components/UI/Input/Date/TimePicker.vue';
import InputGroup from '@/customer/Components/InputGroup.vue';
import { watch } from 'vue';
import moment from 'moment';
import { optionProps } from '@/customer/Components/Order/internal';
import StepHeader from './StepHeader.vue';
import { useOrderStore } from '@/customer/Composables';
import useOrderDelay from '@/company/Composables/useOrderDelay';

const props = defineProps({
    ...optionProps,
});

const order = useOrderStore();
const { currentDay, minTime, maxTime, delayInMinutes, currentLocation } = useOrderDelay(order);

const isToday = ref(true);
const message = ref(null);
const closedHolidays = computed(() => {
    const closed = [];
    const closures = currentLocation?.value?.schedule.filter(
        (schedule) => schedule.starts_at == null,
    );
    closures?.map((closure) => {
        closed.push(moment(closure.override_on).format('YYYY-MM-DD'));
    });
    return closed;
});

const closedDaysOfWeek = computed(() => {
    const daysOfWeek = {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
    };
    const openDays = currentLocation?.value?.schedule
        .filter((schedule) => schedule.fulfillment_type == order.form.fulfillment_type)
        .map((schedule) => schedule.day);
    const closedDays = Object.values(daysOfWeek).filter((day) => !openDays?.includes(day));

    const closedNums = closedDays.map((day) => {
        return parseInt(Object.keys(daysOfWeek).find((k) => daysOfWeek[k] == day));
    });
    return closedNums;
});

function isSameDate(d1, d2) {
    return moment(d1).isSame(moment(d2), 'day');
}

const hideAsap = computed(() => {
    const todaysSchedule = currentLocation.value?.schedule?.find((schedule) => schedule.fulfillment_type == order.form.fulfillment_type && schedule.day == moment().format('dddd'));
    const todayDate = moment().format('YYYY-MM-DD');
    const todayEndsAt = moment(`${todayDate} ${todaysSchedule.ends_at}`, 'YYYY-MM-DD h:mm A');
    const todayStartsAt = moment(`${todayDate} ${todaysSchedule.starts_at}`, 'YYYY-MM-DD h:mm A');

    if (closedDaysOfWeek?.value.includes(moment().day())) {
        message.value = "We are currently closed, but you can schedule your order for the next open day. Either select a time or let us do it for you at the first available slot."
        return true;
        // sunday
    } else if(todayEndsAt.isBefore(moment().add(delayInMinutes?.value, 'minutes')) && todayEndsAt.isAfter(moment())) {
        message.value = "Due to high demand, we're unable to process new " + 
            (order.form.fulfillment_type == 'delivery' ? "deliveries" : "in store orders") + 
            " within today's hours. Either select a time or let us do it for you at the first available slot.";
        return true;
    } else if (moment().add(delayInMinutes?.value, 'minutes').isBefore(todayStartsAt))   { 
        // too early
        message.value = "We're not quite open! Either select a time or let us do it for you at the first available slot."
        return true;
    } else if (closedHolidays?.value.some((holiday) => isSameDate(moment(holiday, 'YYYY-MM-DD').toDate(), moment().toDate()))) {
            message.value = "We are currently closed, but you can schedule your order for the next open day. Either select a time or let us do it for you at the first available slot."
        return true;
        // closed holiday
    } else {
        return false;
    }
});

const calcMinTime = computed(() => {
    if (!isToday?.value) {
        return moment(
            `${moment(scheduled.value.date, 'YYYY-MM-DD h:mm A').format('YYYY-MM-DD')} ${minTime?.value}`,
            'YYYY-MM-DD HH:mm',
        );
    } else {
        // if today opening time is after the current time plus the delay,
        if (
            moment(`${moment().format('YYYY-MM-DD')} ${minTime?.value}`).format('YYYY-MM-DD HH:mm') <
            moment().add(delayInMinutes?.value, 'minutes').format('YYYY-MM-DD HH:mm')
        ) {
            return moment().add(delayInMinutes?.value, 'minutes').format('YYYY-MM-DD HH:mm');
        } else {
            // too early
            return moment(`${moment().format('YYYY-MM-DD')} ${minTime?.value}`, 'YYYY-MM-DD HH:mm');
        }
    }
});

const calcMaxTime = computed(() => {
    if (!isToday?.value) {
        return moment(`${moment(scheduled.value.date, 'YYYY-MM-DD h:mm A').format('YYYY-MM-DD')} ${maxTime?.value}`).format('YYYY-MM-DD HH:mm');
    } else {
        return moment(`${moment().format('YYYY-MM-DD')} ${maxTime?.value}`).format('YYYY-MM-DD HH:mm');
    }
});

const nextOpenDay = computed(() => {
    let nextDay = moment();
    
    if (moment().add(delayInMinutes.value, 'minutes').format('HH:mm') > moment(calcMaxTime?.value).format('HH:mm')) {
        if (isToday?.value && isSameDate(moment(nextDay).toDate(), moment().toDate())) {
            closedHolidays.value.push(moment(nextDay).format('YYYY-MM-DD'));
            nextDay.add(1, 'days');
        }
    }

    while (
        closedHolidays?.value.some((holiday) => isSameDate(moment(holiday, 'YYYY-MM-DD').toDate(), moment(nextDay).toDate())) ||
        closedDaysOfWeek?.value.includes(nextDay.day())
    ) {
        nextDay.add(1, 'days');
    }
    nextDay.set({
        hour: moment(calcMinTime.value).hour(),
        minute: moment(calcMinTime.value).minute(),
    });

    return nextDay.format('YYYY-MM-DD LT');
});

const defaultDate = computed(() => {
    return props.options.length > 1
        ? (nextOpenDay?.value ?? moment().format('YYYY-MM-DD LT'))
        : moment().add(1, 'week').format('YYYY-MM-DD LT');
});

const scheduled = ref({
    date: defaultDate?.value,
    time: defaultDate?.value,
});

isToday.value =
    moment(scheduled.value.date, 'YYYY-MM-DD h:mm A').format('YYYY-MM-DD') ==
    moment().format('YYYY-MM-DD');
currentDay.value = moment(scheduled.value.date, 'YYYY-MM-DD h:mm A').format('dddd');

onMounted(() => {
    scheduled.value = {
        date: nextOpenDay.value,
        time: nextOpenDay.value,
    };
});

watch(
    scheduled,
    (value) => {
        if (
            moment(scheduled.value.date, 'YYYY-MM-DD h:mm A').format('YYYY-MM-DD') !==
            moment(scheduled.value.time, 'YYYY-MM-DD h:mm A').format('YYYY-MM-DD')
        ) {
            scheduled.value.time = moment(
                `${moment(scheduled.value.date, 'YYYY-MM-DD h:mm A').format('YYYY-MM-DD')} ${moment(scheduled.value.time, 'YYYY-MM-DD h:mm A').format('hh:mm A')}`,
                'YYYY-MM-DD hh:mm A',
            );
        }

        if (
            moment(scheduled.value.time, 'YYYY-MM-DD h:mm A').format('HH:mm') >
            moment(calcMaxTime.value).format('HH:mm')
        ) {
            scheduled.value.time = moment(
                `${moment(scheduled.value.date).format('YYYY-MM-DD')} ${moment(calcMaxTime.value).format('hh:mm A')}`,
                'YYYY-MM-DD hh:mm A',
            );
        } else if (
            moment(scheduled.value.time, 'YYYY-MM-DD h:mm A').format('HH:mm') <
            moment(calcMinTime.value).format('HH:mm')
        ) {
            scheduled.value.time = moment(
                `${moment(scheduled.value.date).format('YYYY-MM-DD')} ${moment(calcMinTime.value).format('hh:mm A')}`,
                'YYYY-MM-DD hh:mm A',
            );
        }

        currentDay.value = moment(value.date, 'YYYY-MM-DD h:mm A').format('dddd');
        isToday.value =
            moment(value.date, 'YYYY-MM-DD h:mm A').format('YYYY-MM-DD') ==
            moment().format('YYYY-MM-DD');

        const newReadyAt = moment(scheduled.value.time, 'YYYY-MM-DD h:mm A').format(
            'YYYY-MM-DD HH:mm:00',
        );
        props.form.ready_at = newReadyAt;
    },
    { deep: true },
);

const resetToAsap = () => {
    props.form.ready_type = 'asap';
};
</script>

<template>
    <div>
        <OptionPicker
            :show-options="form.ready_type != 'scheduled'"
            v-model="form.ready_type"
            v-bind="{ prompt }"
            :options="hideAsap ? options.filter((option) => option.value !== 'asap') : options"
            :description="
                options.length > 1
                    ? !hideAsap
                        ? `ASAP orders, we'll start on right away! Current wait time is ${delayInMinutes} minutes. Or, schedule your order for a future time if you're planning ahead!`
                        : ``
                    : 'Caterings require at least one week worth of notice. Upon placing an order, we will reach out to ensure our expectation of greatness matches yours!'
            "
            :errorMessage="form.errors.ready_type"
        />

        <div v-if="form.ready_type === 'scheduled'">
            <div class="mb-10 text-center p-1" v-if="message">*{{ message }}</div>
            <StepHeader class="!py-0 sm:!p-6">
                {{ options.length > 1 ? 'Ready At' : '' }}
            </StepHeader>
            <div v-if="isToday" class="mb-10 text-center">
                <p>The exact time may vary depending on when you complete your order.</p>
            </div>
            <div
                class="mx-auto w-full flex-wrap gap-12 px-4 text-sm font-semibold text-black sm:grid sm:grid-cols-2 sm:px-0 [@media(width>400px)]:px-6"
            >
                <div>
                    <InputGroup
                        id="scheduledDate"
                        name="scheduledDate"
                        label="Date"
                        v-model="scheduled.date"
                        :placeholder="
                            options.length > 1
                                ? 'Today'
                                : moment().add(1, 'week').format('MMMM DD, YYYY')
                        "
                        :type="DatePicker"
                        :minDate="
                            options.length > 1
                                ? moment(nextOpenDay, 'YYYY-MM-DD h:mm A').format(
                                      'YYYY-MM-DD HH:mm',
                                  )
                                : moment().add(1, 'week').format('YYYY-MM-DD HH:mm')
                        "
                        :disabledDaysOfWeek="closedDaysOfWeek"
                        :closedDays="closedHolidays"
                        class="text-gray-600"
                        required
                    />
                </div>

                <div>
                    <InputGroup
                        id="scheduledTime"
                        name="scheduledTime"
                        label="Time"
                        v-model="scheduled.time"
                        placeholder="3:00PM CT"
                        :type="TimePicker"
                        class="text-gray-600"
                        required
                        :minTime="calcMinTime"
                        :maxTime="calcMaxTime"
                    />
                </div>

                <div class="col-span-2 text-center text-xs" v-if="options.length > 1 && !hideAsap">
                    <button class="mx-auto inline-block underline" @click.prevent="resetToAsap()">
                        Change to ASAP?
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
