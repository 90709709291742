<script setup>
import { RadioOptionProps } from '@/customer/Components';

const props = defineProps({
    ...RadioOptionProps,
});

const parseExpiration = (oldFormat) => {
    const indexOfMonth = oldFormat.indexOf('-');
    return `${oldFormat.slice(indexOfMonth + 1)}/${oldFormat.slice(2, 4)}`;
};
</script>
<template>
    <div
        class="w-full space-y-2 rounded border bg-white p-2 text-palmers-black ring-2 ring-offset-2"
        :class="[
            checked
                ? 'bg-palmers-dragon-fruit/10 ring-palmers-dragon-fruit'
                : 'bg-white ring-transparent',
        ]"
    >
        <dl class="flex items-center space-x-2 overflow-hidden">
            <dd class="font-medium">{{ option.billTo.fullName }}</dd>
        </dl>
        <dl class="flex flex-wrap gap-x-2">
            <dd>{{ option.payment.creditCard.cardType }}</dd>
            <dd>{{ option.payment.creditCard.cardNumber }}</dd>
            <dd>{{ parseExpiration(option.payment.creditCard.expirationDate) }}</dd>
        </dl>
    </div>
</template>
