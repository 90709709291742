<script setup>
import useAuth from '@/customer/Composables/useAuth.js';
import ResponsiveNavLink from '@/customer/Components/ResponsiveNavLink.vue';
import { MenuType } from '@/common/Utils/enums.js';
import { ref, onMounted, watch, computed, onUnmounted } from 'vue';
import { AdjustmentsVerticalIcon, ArrowLeftStartOnRectangleIcon } from '@heroicons/vue/20/solid';
import { useOrderStore } from '@/customer/Composables';
import { usePage } from '@inertiajs/vue3';

const order = useOrderStore();

const { auth, check: authCheck } = useAuth();

const props = defineProps({
    logoLink: {
        type: String,
        required: true,
    },
    showResponsiveMenu: {
        type: Boolean,
        required: true,
    },
});

const startAnimation = ref(false);

const headerHeight = ref(0);
const topBannersHeight = ref(0);
const totalHeight = computed(() => headerHeight.value + topBannersHeight.value);
const navPadding = ref(0);

const setHeaderBottom = () => {
    if (totalHeight.value > window.scrollY) {
        navPadding.value = totalHeight.value;
    } else {
        navPadding.value = headerHeight.value;
    }
};

onMounted(() => {
    headerHeight.value = document.getElementById('navHeader')?.getBoundingClientRect().height;
    // topBannersHeight.value = document.getElementById('top-banners').getBoundingClientRect().height;
    setHeaderBottom();
    window.addEventListener('resize', setHeaderBottom());
});

onUnmounted(() => {
    window.removeEventListener('resize', setHeaderBottom());
});

watch(
    () => props.showResponsiveMenu,
    (newVal) => {
        if (newVal) {
            setHeaderBottom();
        }
    },
);

const orderReady = computed(() => usePage().props.order.started && !usePage().props.cart);
</script>

<template>
    <Transition name="background">
        <div
            @click="$emit('handleResponsiveMenu', false)"
            class="fixed inset-0 z-[501] bg-palmers-black/40 sm:hidden"
            v-if="showResponsiveMenu"
        />
    </Transition>
    <Transition name="content">
        <div
            v-if="showResponsiveMenu"
            class="fixed inset-y-0 left-0 z-[502] w-[22rem] bg-palmers-white sm:hidden"
        >
            <div
                class="flex h-full flex-col justify-between gap-y-10 pb-6 pt-8"
                :style="`margin-top: ${navPadding}px`"
            >
                <div class="flex flex-col gap-y-10 pl-6">
                    <ResponsiveNavLink
                        :href="
                            route('customer.menu.index', {
                                type: MenuType.MAIN,
                            })
                        "
                        @handleResponsiveMenu="$emit('handleResponsiveMenu', false)"
                        v-if="!orderReady ? true : order.isCatering ? false : true"
                    >
                        Menu
                    </ResponsiveNavLink>
                    <ResponsiveNavLink
                        :href="
                            route('customer.menu.index', {
                                type: MenuType.CATERING,
                            })
                        "
                        @handleResponsiveMenu="$emit('handleResponsiveMenu', false)"
                        v-if="!orderReady ? true : order.isCatering ? true : false"
                    >
                        Catering
                    </ResponsiveNavLink>
                    <a
                        @click="$emit('handleResponsiveMenu', false)"
                        :href="logoLink"
                        class="inline-flex items-center text-xl font-bold uppercase leading-5 tracking-widest text-black focus:underline active:opacity-80"
                        target="_blank"
                        >Locations</a
                    >
                    <!-- <ResponsiveNavLink
                        :href="route('customer.locations')"
                        @handleResponsiveMenu="$emit('handleResponsiveMenu', false)"
                    >
                        Locations
                    </ResponsiveNavLink> -->
                </div>
                <div class="px-6" :style="`transform: translateY(-${navPadding}px)`">
                    <div v-if="!authCheck() && !route().current('customer.login')">
                        <ResponsiveNavLink
                            :href="route('customer.login')"
                            custom="!text-palmers-white active:!bg-opacity-60 !flex !h-14 !items-center !justify-center !bg-palmers-dragon-fruit"
                            @handleResponsiveMenu="$emit('handleResponsiveMenu', false)"
                        >
                            Login
                        </ResponsiveNavLink>
                    </div>
                    <div v-if="authCheck()">
                        <div>
                            <div class="text-2xl text-palmers-black">
                                {{ auth.user?.name }}
                            </div>
                            <div class="text-md text-palmers-charcoal-grey">
                                {{ auth.user?.email }}
                            </div>
                        </div>

                        <div class="mt-4 flex flex-col gap-3">
                            <ResponsiveNavLink
                                custom="!text-palmers-white active:!bg-opacity-60 !border-2 !border-palmers-dragon-fruit active:!no-underline !rounded-sm !flex !gap-3 !h-14 !items-center !justify-center !bg-palmers-dragon-fruit"
                                @handleResponsiveMenu="$emit('handleResponsiveMenu', false)"
                                :href="route('customer.profile.edit')"
                            >
                                <AdjustmentsVerticalIcon class="size-6" />
                                Profile
                            </ResponsiveNavLink>
                            <ResponsiveNavLink
                                :href="route('customer.logout')"
                                method="post"
                                as="button"
                                custom="!bg-palmers-white active:!bg-palmers-dragon-fruit/60 active:!no-underline active:!text-palmers-white !rounded-sm !flex !gap-3 !h-14 !items-center !justify-center !border-2 !border-palmers-dragon-fruit !text-palmers-dragon-fruit"
                                @handleResponsiveMenu="$emit('handleResponsiveMenu', false)"
                            >
                                <ArrowLeftStartOnRectangleIcon
                                    class="size-6 text-palmers-dragon-fruit"
                                />
                                Log Out
                            </ResponsiveNavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<style scoped>
.background-enter-from,
.background-leave-to {
    opacity: 0;
}

.background-enter-active,
.background-leave-active {
    transition: opacity 350ms ease-in-out;
}

.content-enter-from,
.content-leave-to {
    transform: translateX(-22rem);
}

.content-enter-active,
.content-leave-active {
    transition: transform 350ms ease-in-out;
}
</style>
