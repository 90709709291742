import { defineStore } from 'pinia';
import useApp from './useApp';
import { router, useForm, usePage } from '@inertiajs/vue3';
import { useStepper } from '@vueuse/core';
import { computed, readonly, ref, toValue } from 'vue';
import { find, some, startsWith, get, isEmpty } from 'lodash';
import useCartStore from './useCartStore';
import { FulfillmentType, MenuType } from '@/common/Utils/enums';

export default defineStore('orderStore', {
    state: () => ({
        initialized: false,
        doubleInitialized: false,
        order: null,
        options: null,
        form: null,
        stepper: null,
        fixedTip: null,
        percentageTip: null,
    }),
    getters: {
        auth() {
            return usePage().props.auth;
        },
        selectedLocation() {
            return useApp().locations.find(({ slug }) => slug === this.form.location);
        },
        progress() {
            return {
                ...this.form.data(),
                location: this.selectedLocation,
            };
        },
        isStarted() {
            return this.form.location && this.auth.loggedIn;
        },
        inProgress() {
            return (
                this.form.ready_at !== null &&
                this.auth.loggedIn &&
                !route().current('customer.order.start')
            );
        },
        startOverHref() {
            return this.order.startOverHref;
        },
        menuHref() {
            return this.order.menuHref;
        },
        tips() {
            return this.order.tipping.tips;
        },
        currentQuickTip() {
            return this.percentageTip;
        },
        currentCustomTip() {
            return this.fixedTip;
        },
        tipsAreRequired() {
            return this.order.tipping.required;
        },
        showsNoTip() {
            return this.order.tipping.showsNoTip;
        },
        noTipLabel() {
            return this.order.tipping.noTipLabel;
        },
        isLast() {
            return this.stepper.isLast;
        },
        isFirst() {
            return this.stepper.isFirst;
        },
        currentStep() {
            return this.stepper.current;
        },
        isCarryOut() {
            return this.form.fulfillment_type === FulfillmentType.CARRYOUT;
        },
        isDelivery() {
            return this.form.fulfillment_type === FulfillmentType.DELIVERY;
        },
        goTo() {
            return this.stepper.goTo;
        },
        isCatering() {
            return this.form.menu_type === MenuType.CATERING;
        },
    },
    actions: {
        initialize() {
            if (!this.initialized) {
                this.setOrder(usePage().props.order);
                this.initializeForm();
                this.initializeStepper();
                this.startPolling();
                this.initialized = true;
            }
        },
        startPolling() {
            setInterval(() => {
                if (this.auth.loggedIn && this.doubleInitialized == false) {
                    this.checkAndReinitialize();
                }
            }, 1000); // Check every 1 second
        },
        checkAndReinitialize() {
            const pageOrder = usePage().props.order;
            if (
                this.initialized === true &&
                pageOrder?.order_type !== null &&
                pageOrder?.order_type !== undefined &&
                this.form.order_type == null &&
                pageOrder?.ready_type !== null &&
                pageOrder?.ready_type !== undefined &&
                this.form.ready_type == null
            ) {
                this.setOrder(pageOrder);
                this.initializeForm(true);
                this.initializeStepper();
                this.doubleInitialized = true;
            }
        },
        setOrder(order) {
            this.order = order;
            this.setOptions({
                ...order.options,
                location: {
                    ...order.options.location,
                    options: useApp().locations,
                },
            });
            this.percentageTip = this.order.tipping.percentageTip;
            this.fixedTip = this.order.tipping.fixedTip;
        },
        setOptions(options) {
            this.options = options;
        },
        getOption(key, defaultValue = null) {
            return get(this.options, key) ?? defaultValue;
        },
        getSelectableOption(key, field, value) {
            const options =  get(this.options, key, []);
            return options.filter((option) => option[field] == value)[0]?.slug;
        },
        initializeForm(reinitialize = false) {
            let step = 'location';
            if (reinitialize == true) {
                step = 'ready_type';
            }
            this.form = useForm(() => ({
                step: step,
                location: this.order?.location?.slug || this.getSelectableOption('location.options', 'show_coming_soon', false),
                // order_type: this.order?.order_type || null,
                // menu_type: this.order?.menu_type || null,
                fulfillment_type: this.order?.fulfillment_type || null,
                ready_type: this.order?.ready_type || null,
                ready_at: this.order?.ready_at || null,
                group_invites: this.order?.group_invites || [],
                saved_name: this.order?.saved_name || null,
            }));

            if (reinitialize) {
                this.goToStep(step);
            }
        },
        initializeStepper() {
            this.stepper = useStepper({
                location: { name: 'location', fields: ['location'] },
                // order_type: { name: 'order_type', fields: ['order_type', 'group_invites'] },
                // menu_type: { name: 'menu_type', fields: ['menu_type'] },
                fulfillment_type: { name: 'fulfillment_type', fields: ['fulfillment_type'] },
                ready_type: { name: 'ready_type', fields: ['ready_type', 'ready_at'] },
            });
        },
        partiallyValidate(fields) {
            return new Promise((resolve, reject) => {
                const form = ref(this.form);
                this.form.post(route('customer.order.proceed', route().params), {
                    onSuccess: resolve,
                    onError(errors) {
                        if (
                            !some(fields, (field) =>
                                find(Object.keys(errors), (f) => startsWith(f, field)),
                            )
                        ) {
                            form.value.clearErrors();
                            resolve();
                        }
                    },
                    onFinish: reject,
                });
            });
        },
        async updateReadyAtTime(ready_type, ready_at) {
            this.form.ready_at = ready_at;
            this.form.ready_type = ready_type;
            await this.form.post(route('customer.order.update-ready-time'), {
                onError(errors) {
                    console.error(errors);
                },
            });
        },
        async updateFulfillment(fulfillment_type) {
            this.form.fulfillment_type = fulfillment_type;
            this.order.fulfillment = fulfillment_type;
            await this.form.post(route('customer.order.update-fulfillment'), {
                onError(errors) {
                    console.error(errors);
                },
            });
        },
        async saveOrderName(savedName) {
            this.form.saved_name = savedName;
            await this.form.post(route('customer.order.saveName'), {
                onError(errors) {
                    console.error(errors);
                },
            });
        },
        async goToStep(step, reset = false) {
            router.visit(
                route('customer.order.start', !reset ? {} : { ...route().params, next: true }),
                {
                    onSuccess: (response) => {
                        if (reset) {
                            this.order = response.props.order;
                        }
                        this.stepper.goTo(step);
                    },
                    onError: (error) => {
                        throw new Error(error);
                    },
                },
            );
        },
        goToNextStep() {
            this.form.step = this.currentStep.name;
            this.partiallyValidate(this.currentStep.fields)
                .then(this.stepper.goToNext)
                .catch(() => {});
        },
        goToPreviousStep() {
            this.stepper.goToPrevious();
        },
        async restart() {
            this.doubleInitialized = true;
            await this.form.reset();
            await this.stepper.goTo('location');
            router.visit(route('customer.order.start'), {
                onSuccess: (response) => {
                    this.order = response.props.order;
                    useCartStore().reset();
                },
                onError: (error) => {
                    throw new Error(error);
                },
            });
        },
        toOrderType() {
            this.goToStep('order_type', true).then(() => {
                this.form.menu_type = null;
                this.form.ready_at = null;
                this.form.ready_type = null;
                this.form.fulfillment_type = null;
                useCartStore().fetchCart();
            });
        },
        toMenuType() {
            this.goToStep('menu_type', true).then(() => {
                this.form.ready_at = null;
                this.form.ready_type = null;
                this.form.fulfillment_type = null;
                useCartStore().fetchCart();
            });
        },
        toFulfillmentType() {
            this.goToStep('fulfillment_type', true).then(() => {
                useCartStore().fetchCart();
            });
        },
        isStep(step) {
            return this.stepper.isCurrent(step);
        },
        applyPercentageTip(amount) {
            this.percentageTip = amount;
            this.fixedTip = null;
            useCartStore().applyTip(amount, 'percentage');
        },
        applyFixedTip(amount) {
            this.fixedTip = amount;
            this.percentageTip = null;
            useCartStore().applyTip(amount, 'fixed');
        },
        applyDeliveryFee(amount) {
            useCartStore().addDeliveryFee(amount);
        },
        removeDeliveryFee() {
            useCartStore().removeDeliveryFee();
        },
        async reorder(orderId) {
            await axios.post(route('customer.order.reorder', { orderId }), {
                onSuccess: (response) => {
                    router.visit(response.data.redirect);
                },
                onError: (errors) => {
                    console.error(errors);
                },
            });
        },
    },
});
